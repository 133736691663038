import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/home/Home";
import OurStory from "../pages/OurStory";
import OurSubBrandsPage from "../pages/OurSubBrandsPage";
import ContactUs from "../pages/ContactUs";
import SustainabilityPage from "../pages/SustainabilityPage";
import Offers from "../pages/Offers";
import OfferDetail from "../pages/OfferDetail";
import Media from "../pages/Media";
import CoomingSoon from "../pages/CoomingSoon";
import KanifushiBookDirect from "../pages/KanifushiBookDirect";
import { getPagesSlug } from "../services/dataServices";
import { useQuery } from "react-query";
import VaruBookDIrect from "../pages/VaruBookDIrect";
import KanifushiCelebrateTheSeason from "../pages/KanifushiCelebrateTheSeason";
import ExperienceUnforgettableFestivities from "../pages/ExperienceUnforgettableFestivities";
import ConnectWithNatureAndCultivateRejuvenation from "../pages/KanifushiConnectWithNatureAndCultivateRejuvenation";
import KanifushiEnjoyTheBestOfTheMaldives from "../pages/KanifushiEnjoyTheBestOfTheMaldives";
import KanifushiHowToElevateYourPlantBasedCooking from "../pages/KanifushiHowToElevateYourPlantBasedCooking";
import KanifushiEnrichesCulinaryExperiencesWithTheNewLanternBar from "../pages/KanifushiEnrichesCulinaryExperiencesWithTheNewLanternBar";
import KanifushiAKMJustVegHostsChefFabrizioMarino from "../pages/KanifushiAKMJustVegHostsChefFabrizioMarino";
import VaruExperienceKaageAnAwardWinningEpicureanEscapade from "../pages/VaruExperienceKaageAnAwardWinningEpicureanEscapade";
import VaruChefMohamedNiyazOnCelebratingAuthenticMaldivianFlavours from "../pages/VaruChefMohamedNiyazOnCelebratingAuthenticMaldivianFlavours";
import PressReleaseDeatils from "../pages/PressReleaseDeatils";
import OfferDetailPage from "../pages/offers/OfferDetailPage";
import TermsOfUse from "../pages/privacyPolicy/TermsOfUse";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import PageNotFound from "../components/PageNotFound";
function AllRoutes({ errorPage, setErrorPage }) {
  const getAllRoutes = useQuery("getPagesSlug", getPagesSlug);
  const { isSuccess, isLoading } = getAllRoutes;
  const queryString = window.location.search; // Create a URLSearchParams object to parse the query string
  const urlParams = new URLSearchParams(queryString); // Get values from the query string
  const isPreview = urlParams.get("ispreview");
  const userId = urlParams.get("uid");
  const sectionId = urlParams.get("sid");

  const slugOfHome =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "HOME")[0]
      ?.slug ?? null;
  const slugOfContactUs =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "CONTACTUS")[0]
      ?.slug ?? null;
  const slugOfOurSubBrands =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "OURSUBBRANDS"
    )[0]?.slug ?? null;
  const slugOfOurStory =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "OURSTORY")[0]
      ?.slug ?? null;
  const slugOfSustainbility =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "SUSTAINABILITY"
    )[0]?.slug ?? null;
  const slugOfOffers =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "OFFERS")[0]
      ?.slug ?? null;
  const slugOfMedia =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "MEDIA")[0]
      ?.slug ?? null;
  const slugOfTermsConditon =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "TERMCONDITION"
    )[0]?.slug ?? null;
  const slugOfPrivacyPolicy =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "PRIVACYPOLICY"
    )[0]?.slug ?? null;
 

  return (
    <>
      <Routes>
        <Route
          path={`/`}
          element={
            <Home isPreview={isPreview} userId={userId} sectionId={sectionId} slugOfSustainbility={slugOfSustainbility} slugOfOurStory={slugOfOurStory} slugOfOffers={slugOfOffers} />
          }
        ></Route>
        <Route
          path={`/${slugOfOurStory}`}
          element={
            <OurStory
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfOurSubBrands}`}
          element={
            <OurSubBrandsPage
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfContactUs}`}
          element={
            <ContactUs
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfSustainbility}`}
          element={
            <SustainabilityPage
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfOffers}`}
          element={
            <Offers
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              slugOfOffers={slugOfOffers}
            />
          }
        ></Route>

        <Route
          path={`/${slugOfOffers}/:offerId`}
          element={<OfferDetailPage slugOfOffers={slugOfOffers} setErrorPage={setErrorPage} />}
        ></Route>
        {/* <Route path='/offer-details' element={<OfferDetail/>}></Route> */}

        <Route
          path="/kanifushi-celebrate-the-season"
          element={<KanifushiCelebrateTheSeason />}
        ></Route>
        <Route path="/varu-book-direct" element={<VaruBookDIrect />}></Route>
        <Route
          path="/Experience-Unforgettable-Festivities"
          element={<ExperienceUnforgettableFestivities />}
        ></Route>
        <Route
          path={`/${slugOfMedia}`}
          element={
            <Media
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              slugOfMedia={slugOfMedia}
            />
          }
        ></Route>
        <Route
          path="/connect-with-nature-and-cultivate-rejuvenation-at-atmosphere-kanifushi-in-the-maldives"
          element={<ConnectWithNatureAndCultivateRejuvenation />}
        ></Route>
        <Route
          path="/enjoy-the-best-of-the-maldives-in-complete-privacy-at-the-atmosphere-kanifushi"
          element={<KanifushiEnjoyTheBestOfTheMaldives />}
        ></Route>
        <Route
          path="/how-to-elevate-your-plant-based-cooking-this-summer-season"
          element={<KanifushiHowToElevateYourPlantBasedCooking />}
        ></Route>
        <Route
          path="/atmosphere-kanifushi-enriches-culinary-experiences-with-the-new-lantern-bar"
          element={<KanifushiEnrichesCulinaryExperiencesWithTheNewLanternBar />}
        ></Route>
        <Route
          path="/akm-just-veg-hosts-chef-fabrizio-marino"
          element={<KanifushiAKMJustVegHostsChefFabrizioMarino />}
        ></Route>
        <Route
          path="/experience-kaage-an-award-winning-epicurean-escapade-this-monsoon-in-the-maldives"
          element={<VaruExperienceKaageAnAwardWinningEpicureanEscapade />}
        ></Route>
        <Route
          path="/chef-mohamed-niyaz-on-celebrating-authentic-maldivian-flavours"
          element={
            <VaruChefMohamedNiyazOnCelebratingAuthenticMaldivianFlavours />
          }
        ></Route>
        <Route
          path={`/${slugOfMedia}/:websitePressReleaseId`}
          element={<PressReleaseDeatils slugOfMedia={slugOfMedia} setErrorPage={setErrorPage} />}
        ></Route>
        <Route path="/coming-soon" element={<CoomingSoon />}></Route>
        <Route
          path={`/${slugOfTermsConditon}`}
          element={
            <TermsOfUse
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}

            />
          }
        ></Route>
        {slugOfPrivacyPolicy!=""?
        <Route
          path={`/${slugOfPrivacyPolicy}`}
          element={
            <PrivacyPolicy
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>:""}
        {isSuccess ? (
          <Route
            path="/*"
            element={
              <PageNotFound errorPage={errorPage} setErrorPage={setErrorPage} />
            }
          ></Route>
        ) : (
          ""
        )}
      </Routes>
    </>
  );
}

export default AllRoutes;
