import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { getResortData } from "../services/dataServices";
import { Button } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";

function Modals({ show, handleClose }) {
  let currentDateTime = new Date();
  const currentDate = currentDateTime.getDate();
  const nextDate = new Date(currentDateTime.setDate(currentDate + 1))
  const [bookingStartDate, setStart] = useState(new Date());
  const [bookingEndDate, setEnd] = useState(nextDate);
  const [selectedItemResort, setSelectedItem] = useState();
  const allResortdata = useQuery('allresortdata', getResortData)


  /* when getting error from resort API than this static data use in else condition */
  const resortData = allResortdata?.data?.data == null ? [
    { brand_resort_id: 1, resort_code: "95216", resort_name: "Atmosphere - Kanifushi" },
    { brand_resort_id: 2, resort_code: "110184", resort_name: "By Atmosphere - Varu" },
  ] : allResortdata.data.data;

  // useEffect(() => {
  //   // Function to fetch data from the API
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         "http://172.16.1.113:8000/api/v1/other/get-ahr-resorts-list"
  //       ); // Replace with your API endpoint
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const result = await response.json();
  //       setData(result.data);
  //     } catch (error) {
  //       setError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   // Call the fetchData function
  //   fetchData();
  // }, []); //

  const handleSelectChange = (event) => {
    const selectedItemCode = event.target.value;
    const selectedItemResort = resortData.find(
      (item) => item.resort_code === selectedItemCode
    );
    setSelectedItem(selectedItemResort);
    setFormData({
      ...formData,
      resort: selectedItemCode,
    });
  };

  const [formData, setFormData] = useState({
    resort: "",
    discountCode: "",
    iataCode: "",
    adultCount: "2",
    roomCount: "1",
    childrenCount: 0,
    childrenAges: Array(0).fill(6),
  });

  ///adult count
  const adultIncrementBtn = (event) => {
    event.preventDefault();
    setFormData({ ...formData, adultCount: +formData.adultCount + 1 });
  };

  const adultDecrementBtn = (e) => {
    e.preventDefault();
    if (formData.adultCount > 1) {
      setFormData({ ...formData, adultCount: +formData.adultCount - 1 });
    }
  };
  /* End adult count */

  /* Start children count*/
  const childrenIncrement = (event) => {
    event.preventDefault();
    setFormData({ ...formData, childrenCount: +formData.childrenCount + 1 });
  };

  const childrenDecrement = (event) => {
    event.preventDefault();
    if (formData.childrenCount > 0) {
      setFormData({ ...formData, childrenCount: +formData.childrenCount - 1 });
    }
  };
  /* End children count*/

  /* Start Room count*/

  const roomIncrementBtn = (event) => {
    event.preventDefault();
    setFormData({ ...formData, roomCount: +formData.roomCount + 1 });
  };

  const roomDecrementBtn = (event) => {
    event.preventDefault();
    if (formData.roomCount > 1) {
      setFormData({ ...formData, roomCount: +formData.roomCount - 1 });
    }
  };
  /* End Room count*/

  const handleDiscountCodeChange = (event) => {
    setFormData({
      ...formData,
      discountCode: event.target.value,
    });
  };
  const handleIataCodeChange = (event) => {
    setFormData({
      ...formData,
      iataCode: event.target.value,
    });
  };

  // Format date to MM-DD-YYYY
  const formatDate = (date) => format(date, "MM/dd/yyyy");

  /*Submit form Action Start here */
  const handleSubmit = (event) => {
    event.preventDefault();

    // Access formData for submission or further processing

    const queryParams = new URLSearchParams();
    // queryParams.set('resortId',formData.resort)
    queryParams.set("adults", formData.adultCount);
    queryParams.set("children", formData.childrenCount);
    queryParams.set("childage", formData.childrenAges);
    queryParams.set("datein", formatDate(bookingStartDate));
    queryParams.set("dateOut", formatDate(bookingEndDate));
    queryParams.set("rooms", formData.roomCount);
    queryParams.set("discount", formData.discountCode);
    queryParams.set("iata", formData.iataCode);

    console.log("formData.resort",formData.resort)
    if (formData.resort > 0) {
      const newTab = window.open(
        `https://reservations.travelclick.com/${formData.resort
        }?${queryParams.toString()}`,
        "_blank"
      );
      // Focus on the new tab (optional)
      if (newTab) {
        newTab.focus();
      }
    } else {
      const newTab = window.open(
        `https://search.atmospherehotelsandresorts.com/AHR/AtmosphereHotelsandResorts#/`,
        // `https://search.atmospherehotelsandresorts.com/ahr/ATMOSPHERE/`,
        "_blank"
      );
      // Focus on the new tab (optional)
      if (newTab) {
        newTab.focus();
      }
    }
  };
  /**End Submit form here **/



  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error: {error.message}</p>;
  // }

  const handleChildrenCountChange = (event, increment, fieldName) => {
    event.preventDefault();

    setFormData((prevFormData) => {
      const currentCount = Number(prevFormData[fieldName]) || 0;
      // const newCount = Math.max(0, Math.min(11, currentCount + increment)); // Enforce range [0, 11]
      const newCount = Math.max(0, currentCount + increment);

      // Adjust the childrenAges array to match the new count
      const updatedChildAges = [...prevFormData.childrenAges];
      if (newCount > currentCount) {
        // Add new entries for additional children
        for (let i = currentCount; i < newCount; i++) {
          updatedChildAges.push(6); // Default age to 6 for new children
        }
      } else if (newCount < currentCount) {
        // Remove extra entries
        updatedChildAges.splice(newCount);
      }

      return {
        ...prevFormData,
        [fieldName]: newCount,
        childrenAges: updatedChildAges,
      };
    });
  };

  const handleChildAgeChange = (index, increment) => {
    setFormData((prevFormData) => {
      const newChildrenAges = [...prevFormData.childrenAges];
      const newAge = Math.max(0, Math.min(11, newChildrenAges[index] + increment)); // Enforce age range [0, 11]
      newChildrenAges[index] = newAge;
      return { ...prevFormData, childrenAges: newChildrenAges };
    });
  };

  const [paddingRight, setPaddingRight] = useState(0);
  
    const handleUpdate = ({ scrollHeight, clientHeight }) => {
      // Check if the scrollbar is visible
      if (scrollHeight > clientHeight) {
        setPaddingRight(16); // Width of the scrollbar (adjust as needed)
      } else {
        setPaddingRight(0); // No scrollbar, remove padding
      }
    };


  return (
    <Modal className="book-now-modal" show={show} onHide={handleClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className="h2 text-white georgia text-uppercase">
          BOOK YOUR STAY
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-4">
            <Form.Label for="Resort" className="text-uppercase text-white">
              HOTEL/RESORT
            </Form.Label>
            <Form.Select
              onChange={handleSelectChange}
              className="form-control form-select shadow-none"
              aria-label="Default select example"
            >
              <option value="" className="d-none" selected>Select</option>
              <option value="" disabled className="py-4">Maldives</option>
              {/* <option value="option1">Atmosphere - Kanifushi</option>
              <option value="option2">By Atmosphere - Varu</option>
              <option value="">---Select---</option> */}
              {allResortdata && allResortdata?.data?.data?.map((resort) => (
                <option key={resort.brand_resort_id} value={resort.resort_code}>
                  {resort.resort_name}
                </option>
              ))}
            </Form.Select>
          </div>
          {/* /mb-4 */}
          <div className="mb-4">
            <Form.Label for="Resort" className="text-uppercase text-white">
              Check in <span className="mx-1">-</span> Check out
            </Form.Label>
            <DateRangePicker
              initialSettings={{
                locale: {
                  format: "DD/MM/YYYY",
                },
                startDate: bookingStartDate,
                endDate: bookingEndDate,
                minDate: new Date(),
                autoApply: true,
              }}
              onApply={(event, picker) => {
                setStart(new Date(picker.startDate));
                setEnd(new Date(picker.endDate));
              }}
            >
              <input
                type="text"
                className="form-control shadow-none text-start "
              />
            </DateRangePicker>
          </div>
          <div className="row mb-4">
            <div className="col-sm-4">
              <Form.Label className="text-uppercase text-white">
                Rooms
              </Form.Label>
              <div className="input-number position-relative">
                <button
                  className="input-number-increment"
                  onClick={roomDecrementBtn}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <input
                  type="number"
                  value={formData.roomCount}
                  id="inuput-number"
                  className="form-control shadow-none text-center input-arrow"
                />
                <button
                  className="input-number-decrement"
                  onClick={roomIncrementBtn}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </div>
            <div className="col-sm-4">
              <Form.Label className="text-uppercase text-white">
                Adults
              </Form.Label>
              <div className="input-number position-relative">
                <button
                  className="input-number-increment"
                  onClick={adultDecrementBtn}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <Form.Control
                  type="number"
                  id="inuput-number"
                  className="form-control shadow-none text-center"
                  value={formData.adultCount}
                />
                <button
                  className="input-number-decrement"
                  onClick={adultIncrementBtn}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </div>
            <div className="col-sm-4">
              <Form.Label className="text-uppercase text-white">
                Children
              </Form.Label>
              <Button
                // onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                className="p-0 border-0 bg-transparent shadow-none chrld-btn w-100"
              // aria-expanded={open}
              >
                <div className="input-number position-relative">

                  <div
                    className="input-number-increment h-100 d-flex justify-content-end align-items-center"
                    onClick={(e) => handleChildrenCountChange(e, -1, "childrenCount")}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </div>

                  <Form.Control
                    type="number"
                    id="inuput-number"
                    className="form-control shadow-none text-center border-0"
                    value={formData.childrenCount}
                  />
                  <div
                    className="input-number-decrement h-100 d-flex justify-content-start align-items-center"
                    onClick={(e) => handleChildrenCountChange(e, +1, "childrenCount")}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </div>
              </Button>
              
            </div>
          </div>
          <div className="mb-4" id="example-collapse-text">
          {/* <Scrollbars autoHeight autoHeightMax={220} onUpdate={handleUpdate}>
              <div className="w-full overflow-hidden" style={{ paddingRight: `${paddingRight}px`,}}> */}
            <div className="row gy-3">             
              {formData?.childrenAges?.map((age, index) => (
                <div className="col-sm-4 col-6" key={index}>
                  {/* <label className='form-label uppercase mb-1 flex'>Child {index + 1} AGE</label> */}
                  <Form.Label className="text-uppercase text-white" >
                    Child {index + 1} AGE
                  </Form.Label>
                  <div className="input-number position-relative" >
                    <span
                      className="input-number-increment h-100 d-flex align-items-center justify-content-end cursor-pointer" onClick={() => handleChildAgeChange(index, -1)}><FontAwesomeIcon icon={faAngleLeft} /></span>
                    <Form.Control type='number' readOnly value={age} className="form-control shadow-none text-center" />
                    <span
                      className="input-number-decrement h-100 d-flex align-items-center  cursor-pointer" onClick={() => handleChildAgeChange(index, 1)}> <FontAwesomeIcon icon={faAngleRight} /></span>
                  </div>
                </div>

              ))}

            </div>
            {/* </div>
            </Scrollbars> */}
          </div>
          {/* /row */}
          <div className="row mb-4">
            <div className="col-md-6">
              <Form.Label className="text-uppercase text-white">
                Discount Code
              </Form.Label>
              <Form.Control
                value={formData.discountCode}
                type="text"
                className="form-control shadow-none"
                placeholder="Enter code here"
                onChange={handleDiscountCodeChange}
              />
            </div>
            <div className="col-md-6">
              <Form.Label className="text-uppercase text-white">
                IATA Code
              </Form.Label>
              <Form.Control
                value={formData.iataCode}
                type="text"
                className="form-control shadow-none"
                placeholder="Enter code here"
                onChange={handleIataCodeChange}
              />
            </div>
          </div>
          {/* /row */}
          <div className="pt-3">
            <div className="form-submit">
              <input
                type="submit"
                className="btn primary theme-btn fw-medium text-white text-uppercase border-theme"
                value={"CHECK AVAILABILITY"}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Modals;
