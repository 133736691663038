import React from "react";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import TabItem from "./TabItem";
import Image from "../components/Image";
import PlaceholderImage from "../assets/images/ahr2.png";

const SubBrandTabs = ({ data = [] }) => {

  const subSectionsData = data?.subSectionData;

  // define state with initial value to let the tabs start with that value
  const [activeTab, setActiveTab] = React.useState(0);
  // define a onClick function to bind the value on tab click
  const onTabClick = (e, index) => {

    setActiveTab(index);
  };


  const excludedSubSectionTitles = [
    "DISCOVER1",
    "DISCOVER2",
    "DISCOVER3",
    "DISCOVER4",
    "DISCOVERBOOKNOW",
  ];
  const validCodes = ["DISCOVER1", "DISCOVER2", "DISCOVER3"];

  return (
    <>
      <Tabs
        activeTab={activeTab}
        onTabClick={onTabClick}
        tabsArray={subSectionsData}
        tabsScrollAmount={1}
      >
        {subSectionsData?.map((item, index) => (
          <Tab
            key={index}
            className={`item ${activeTab === +item.page_sub_section_id
              ? "active text-uppercase d-flex align-items-center"
              : "text-uppercase d-flex align-items-center"
              }`}
          >
            <div className="position-relative tab-contain">
            <span className="tab-feature-img d-flex rounded-circle overflow-hidden">
              <Image
                src={
                  item.elements.filter(
                    (image) => image.field_title_code === "TABIMAGE"
                  )[0].data || PlaceholderImage
                }
                alt={item.elements.filter(
                  (image) => image.field_title_code === "TABIMAGE"
                )[0].img_alt_tag}

                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // Prevents looping
                  currentTarget.src = PlaceholderImage; // Replace with the path to your dummy image
                }}
                title=""
              />
              {/* <img
                src={
                  item.elements.filter(
                    (image) => image.field_title_code === "TABIMAGE"
                  )[0].data
                }
                alt={ item.elements.filter(
                    (image) => image.field_title_code === "TABIMAGE"
                  )[0].img_alt_tag}
                title=""
              /> */}
            </span>
            <div className="gradnt-brdr d-flex justify-content-center align-items-center w-100">
              <span className="tab-name text-white d-flex justify-content-center align-items-center h-100">{item.sub_section_title}</span>
            </div>
            </div>
          </Tab>
        ))}
      </Tabs>

      {subSectionsData?.map((screen, index) => (
        <TabScreen
          key={+screen.page_sub_section_id}
          activeTab={activeTab}
          index={index}
        >
          <TabItem
            index={index}
            coverImage={
              screen.elements.filter(
                (item) => item.field_title_code === "IMAGE"
              )[0].data
            }
            imgAltTag={screen.elements.filter(
              (item) => item.field_title_code === "IMAGE"
            )[0].img_alt_tag}
            tabLogo={
              screen.elements.filter(
                (item) => item.field_title_code === "LOGO"
              )[0].data
            }
            brandSubTitle={
              screen.elements.filter(
                (item) => item.field_title_code === "HEADING"
              )[0].data
            }
            brandtabs={screen.brandtabs}
            tabPara={
              screen.elements.filter(
                (item) => item.field_title_code === "CONTENT"
              )[0].data
            }
            hotelImage={screen.hotelImage}
            hotelLink={screen.hotelLink}
            hotelName={screen.hotelName}
            hotelLocation={screen.hotelLocation}
            tabResorts={screen.children.filter(
              (item) =>
                !excludedSubSectionTitles.includes(item.sub_section_title_code)
            )}
            discoverIcon={screen.discoverIcon}
            tabDiscoverLeftSideCards={screen.children.filter((item) =>
              validCodes.includes(item.sub_section_title_code))
              .sort((a, b) => validCodes.indexOf(a.sub_section_title_code) - validCodes.indexOf(b.sub_section_title_code))
            }
            // tabDiscoverRightSideCards={screen.children.filter(
            //   (item) => item.sub_section_title_code === "DISCOVER4"
            // )}
            // linksData={screen.children.filter(
            //   (item) => item.sub_section_title_code === "DISCOVERBOOKNOW"
            // )}
            visitResort={screen.visitResort}
            visitResortName={screen.visitResortName}
            targetVisitResort={screen.targetVisitResort}
          />
        </TabScreen>
      ))}
    </>
  );
};

export default SubBrandTabs;
