import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../components/Image'

function TabResortsView({ resortImg, resortName, resortLocation, resortImgAltTag,resortLink, resrotVisitName = "Coming Soon", linkTarget }) {



  return (
    <>
      <div className='resort-item'>
        <div className='resort-pic'>
        <Image src={resortImg} alt={resortImgAltTag} title="" />
        {/* <img src={resortImg} alt={resortImgAltTag} title="" /> */}
        </div>
        <div className='resort-name-location text-uppercase'>
          <h6 className='georgia'>{resortName}</h6>
          <span className='text-uppercase'>{resortLocation}</span>
          <div className='visit-now'>
            {/* {resrotVisitName?():()} */}
            {/* {linkTarget?():()} */}
            <Link to={linkTarget ? linkTarget : ""} target={linkTarget} className= {`text-uppercase ${linkTarget?("our_subbrand_ga4"):("")}`}>{linkTarget ? "EXPLORE" : "Coming Soon"}<FontAwesomeIcon className='ms-2' icon={faAngleRight} /></Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default TabResortsView