import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "../components/Analytics";
const RouteTracker = () => {
  const location = useLocation();
console.log("location.pathname",location.pathname)
  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);
  return null;
};
export default RouteTracker;